
/* AUTO SUGGEST INPUT */

.react-autosuggest__container {
    position: relative;
    width: 100%;
}
  
.react-autosuggest__input {
    width: 100%;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
    width: 100%;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    z-index: 30;
    position: absolute;
    top: 43px;
    width: 314px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #00acc1;
    color: #FFF;
}