.markercluster-map {
  height: 700px;
}

.markercluster-map .marker-cluster-custom {
  background: rgb(168, 3, 233);
  border: 3px solid #aaa;
  border-radius: 50%;
  color: #aaa;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

.map-avatar{
  width: 150px;
  height: 150px;
  border-radius: 5px;
}

.map-popup{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 200px;
}

.map-popup-title{
  font-weight: bold;
  font-size: 20px;
  color: #333;
}