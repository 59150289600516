.skeleton-container{
    font-size: 0px;
    overflow: hidden;
    background-color: transparent;
    border-radius: 20px;
}

.smart-image-circular-container{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.smart-image{
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.smart-image-blur {
    filter: blur(20px);
}