.smart-video-thumb {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.smart-video {
  z-index: 0;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.smart-video-container {
  position: relative;
  overflow: hidden;
}
